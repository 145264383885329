import React, { useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import useGetApi from "../hooks/useGetApi";
import usePostApi from "../hooks/usePostApi";
import Header from "../partials/Header";
import { Container, Text, Box, Heading, Textarea, Button, Flex, useToast } from "@chakra-ui/react";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from '@chakra-ui/react'
import { stringify } from "querystring";

const ThreadDetail: React.FC = () => {
    const user = useAuth();
    const toast = useToast();
    const navigate = useNavigate();  // useHistory フックを追加
    const { id } = useParams<{ id?: string }>();
    const threadId = id ? parseInt(id, 10) : null;

    const [thread, setThread] = useState<any>(null);
    const [instruction, setInstruction] = useState<string>("");

    const threadUrl = `${process.env.REACT_APP_BACKEND_APP_URL}/api/thread/${threadId}/`
    const { fetchData, loading: apiLoading, error: apiError } = useGetApi<any>(
        threadUrl,
        null
    );

    const { postData: threadPostData, response: threadPostResponse, loading: threadPostLoading, error: threadPostError } = usePostApi<any, any>(
        threadUrl,
    );

    const addChatBodyToRelationUrl = threadUrl + "relation-comment/"
    const { postData: postRelationCommentChatData, response: postRelationCommentChatDataResponse, error: postRelationCommentChatDataError } = usePostApi<any, any>(
        addChatBodyToRelationUrl,
    );


    const handleInstructionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setInstruction(event.target.value);
    };

    const submitInstruction = async () => {
        try {
            const newChat = {
                "id": null,
                "chat_body": instruction,
                "chat_at": new Date().toISOString(),
                "from_agent": false,
                "staff": user ? (user.last_name + user.first_name) : ""
            };
            setThread((prevState: any) => ({
                ...prevState,
                chats: sortChatsByDate([...prevState.chats, newChat])
            }));
            setInstruction(""); // 指示送信後にテキストエリアをクリア
            await threadPostData({
                "message": instruction
            })
        } catch (error) {
            console.error("Failed to submit instruction:", error);
        }
    };

    useEffect(() => {
        if (threadPostResponse) {
            setThread((prevState: any) => ({
                ...prevState,
                chats: [...prevState.chats, threadPostResponse]
            }));
            setInstruction(""); // テキストエリアをクリア
        }
    }, [threadPostResponse]);

    useEffect(() => {
        if (threadId) {
            const getThreadDetail = async () => {
                try {
                    const data = await fetchData();
                    if (data && data.chats) {
                        // chats を日時順にソート
                        const sortedChats = sortChatsByDate(data.chats)
                        data.chats = sortedChats;
                    }
                    setThread(data);
                } catch (error) {
                    console.error("Failed to fetch thread details:", error);
                }
            };

            getThreadDetail();
        }
    }, [threadId]);


    const openTrainDataModal = (chat: any) => {
        const classificationId = thread.chats[0].classification.id; // チャットオブジェクトからclassification_idを取得
        const inquiry = thread.ticket.messages.slice(-1)[0].body;
        const reply = chat.chat_body;

        const queryParams = new URLSearchParams({
            inquiry: encodeURIComponent(inquiry),
            reply: encodeURIComponent(reply),
            open_new_modal: "true"
        }).toString();

        navigate(`/classification/${classificationId}/train-data?${queryParams}`); // リダイレクト時にURLパラメータを付与
    };



    const addChatBodyToRelation = async (chatId: number) => {
        try {
            await postRelationCommentChatData({ "chat_id": chatId });

            if (postRelationCommentChatDataResponse !== null) {
                setThread((prevState: any) => ({
                    ...prevState,
                    chats: sortChatsByDate([...prevState.chats, postRelationCommentChatDataResponse])
                }));
                toast({
                    title: '成功',
                    description: "Re:lationのコメント更新に成功しました",
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                    position: 'bottom'
                });
            } else {
                throw new Error(String(postRelationCommentChatDataError));
            }
        } catch (error) {
            console.error("Re:lationのコメント更新に失敗しました:", error);
            toast({
                title: 'エラー',
                description: `Re:relationのコメント更新に失敗しました: ${error}`,
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom'
            });
        }
    };

    const sortChatsByDate = (chats: any) => {
        return chats.sort((a: any, b: any) => new Date(a.chat_at).getTime() - new Date(b.chat_at).getTime());
    };


    return (
        <div>
            <Header />
            <Container maxW="container.xl" py={5}>
                {apiLoading && <Text>Loading...</Text>}
                {apiError && <Text color="red.500">Error: {apiError}</Text>}
                {thread && (
                    <Box>
                        <Heading as="h2" size="lg" mb={4}>{thread.title}</Heading>
                        <Box p={4} borderWidth="1px" borderRadius="lg" mb={4} bg='brand.300' border={"4px solid #bbb"}>
                            <Text>チケットURL：<Link href={'https://commerce-media.relationapp.jp/tickets/' + thread.ticket.relation_ticket_id} isExternal>https://commerce-media.relationapp.jp/tickets/{thread.ticket.relation_ticket_id}</Link></Text>
                            <Text mb={2}><strong>作成日:</strong> {new Date(thread.ticket.relation_created_at).toLocaleString()}</Text>
                            <Text><strong>問い合わせ内容</strong></Text>
                            <Text mb={2}><strong>件名:</strong> {thread.ticket.messages.slice(-1)[0].title}</Text>
                            <Text mb={2}>{thread.ticket.messages.slice(-1)[0].body}</Text>
                        </Box>
                        {thread.chats.map((chat: any) => (
                            <Box key={chat.id} p={4} borderWidth="2px" borderRadius="lg" mb={4} borderColor={chat.from_agent ? 'brand.700' : 'gray.700'} mr={chat.from_agent ? 20 : 0} ml={!chat.from_agent ? 20 : 0} bg={!chat.from_agent ? 'brand.300' : 'white'}>
                                <Flex justifyContent="space-between">
                                    <Box>
                                        <Text color={chat.from_agent ? 'brand.700' : 'gray.700'}><strong>送信者:</strong> {chat.from_agent ? "CS回答生成エージェント" : chat.staff}</Text>
                                        <Text mb={2}><strong>送信日時:</strong> {new Date(chat.chat_at).toLocaleString()}</Text>
                                    </Box>
                                    <Box display="flex" flexDirection={"column"}>
                                        {chat.from_agent && (
                                            <>
                                                <Button colorScheme="brand" variant="link" size="sm" onClick={() => openTrainDataModal(chat)}>教師データに追加</Button>
                                                {!chat.relation_commented_at && (<Button colorScheme="brand" variant="outline" size="sm" mt="4" onClick={() => addChatBodyToRelation(chat.id)}>Re:lation更新</Button>)}
                                            </>
                                        )}
                                    </Box>
                                </Flex>
                                <Text mb={2}>{chat.chat_body}</Text>
                            </Box>
                        ))}
                    </Box>
                )}
                <Box p={4} borderWidth="1px" borderRadius="lg">
                    <Textarea
                        value={instruction}
                        onChange={handleInstructionChange}
                        placeholder="回答をブラッシュアップする指示を入力..."
                        size="sm"
                        resize="none"
                    />
                    <Button mt={4} colorScheme="brand" onClick={submitInstruction} isLoading={threadPostLoading} >送信</Button>
                </Box>
            </Container>
        </div >
    );
};

export default ThreadDetail;