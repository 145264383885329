import { useState } from "react";
import axios from "axios";
import { useToast } from "@chakra-ui/react";

const usePutApi = <T, U>(url: string) => {
    const [response, setResponse] = useState<T | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    // Chakra UIのToastフックを初期化
    const toast = useToast();

    const putData = async (data: U) => {
        setLoading(true);
        try {
            const result = await axios.put(url, data, {
                withCredentials: true,
            });
            setResponse(result.data);

            // 成功した場合のToastメッセージ
            toast({
                title: "成功",
                description: "データが正常に更新されました。",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (err) {
            setError("データの更新に失敗しました。");

            // エラー発生時のToastメッセージ
            toast({
                title: "エラー",
                description: "データの更新に失敗しました。",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };

    return { putData, response, loading, error };
};

export default usePutApi;
