import React, { useEffect, useState, useCallback } from "react";
import { Container, Heading, Table, Thead, Tbody, Tr, Th, Td, Flex, Button, Text, Modal, ModalOverlay, ModalBody, ModalCloseButton, ModalContent, ModalHeader, useDisclosure, FormControl, FormLabel, Textarea, Switch, useToast } from "@chakra-ui/react";
import { useParams, useSearchParams } from "react-router-dom";
import Header from "../partials/Header";
import useAuth from "../hooks/useAuth";
import useGetApi from "../hooks/useGetApi";
import usePostApi from "../hooks/usePostApi";

const TrainDataList: React.FC = () => {
    const toast = useToast();
    useAuth();
    const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
    const { classificationId } = useParams();

    const [searchParams, setSearchParams] = useSearchParams();

    const PER_PAGE = 10;
    const page = parseInt(searchParams.get('page') || '1', 10);

    const [trainData, setTrainData] = useState<any[]>([]);
    const [totalTrainData, setTotalTrainData] = useState(0);

    const fetchTrainDataUrl = `${process.env.REACT_APP_BACKEND_APP_URL}/api/classification/${classificationId}/train-data/?limit=${PER_PAGE}&offset=${(page - 1) * PER_PAGE}`;

    const { fetchData } = useGetApi<any>(
        fetchTrainDataUrl,
        []
    );

    const [modalId, setModalId] = useState(null)
    const [modalQuestion, setModalQuestion] = useState("")
    const [modalAnswer, setModalAnswer] = useState("")
    const [modalTrainedAt, setModalTrainedAt] = useState("未反映")
    const [modalIsActive, setModalIsActive] = useState(true)


    const fetchTrainData = useCallback(async () => {
        try {
            const fetchTrainData = await fetchData();
            setTrainData(fetchTrainData.results);
            setTotalTrainData(fetchTrainData.count);
        } catch (error) {
            console.error("データの取得に失敗しました。", error);
        }
    }, [fetchData]);

    useEffect(() => {
        fetchTrainData();
    }, [page, fetchTrainData]);

    const totalPages = Math.ceil(totalTrainData / PER_PAGE); // 総ページ数を計算

    const goToPage = (pageNumber: number) => {
        setSearchParams({
            page: pageNumber.toString(),
        });
    };

    const postTrainDataUrl = `${process.env.REACT_APP_BACKEND_APP_URL}/api/classification/${classificationId}/train-data/`;
    const { postData: postTrainData, response: postTrainDataResponse, error: postTrainDataError } = usePostApi<any, any>(
        postTrainDataUrl,
    );


    const generateVectorStoreUrl = `${process.env.REACT_APP_BACKEND_APP_URL}/api/classification/${classificationId}/generate-vector-store/`;
    const { fetchData: generateFetchData } = useGetApi<any>(
        generateVectorStoreUrl,
        []
    );

    // メモ入力とクリップ保存のモーダルを表示するための関数
    const openModal = (trainData: any) => {
        setModalId(trainData.id);
        setModalQuestion(trainData.question);
        setModalAnswer(trainData.answer);
        setModalIsActive(trainData.is_active);
        if (trainData.trained_at != null) {
            setModalTrainedAt("反映済：" + String(new Date(trainData.trained_at).toLocaleString()))
        } else {
            setModalTrainedAt("未反映")
        }
        onModalOpen(); // モーダルを開く
        // ここで選択されたchatIdを何らかの方法で記録しておく必要があります
    };

    const handleModalSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onModalClose();
        handlePostTrainData();
        setModalId(null);
        setModalQuestion("");
        setModalTrainedAt("未反映")
        setModalAnswer("");
    }

    const handleModalDeleteSubmit = async () => {
        onModalClose();
        await postTrainData({
            "action": "delete",
            "id": modalId,
        });
        fetchTrainData();
        setModalId(null);
        setModalQuestion("");
        setModalAnswer("");
    }

    const handlePostTrainData = async () => {
        if (modalId === "new") {
            await postTrainData({
                "action": "create",
                "question": modalQuestion,
                "answer": modalAnswer,
                "is_active": modalIsActive ? "true" : "false",
            });
        } else if (modalId) {
            await postTrainData({
                "action": "update",
                "id": modalId,
                "question": modalQuestion,
                "answer": modalAnswer,
                "is_active": modalIsActive ? "true" : "false",
            });
        }
    };

    const generateVectorStore = async () => {
        try {
            const data = await generateFetchData();
            console.log(data);
            toast({
                title: '成功',
                description: "教師データのvectorStoreの作成が完了しました。",
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'bottom'
            });
        } catch (error) {
            console.error("Failed to fetch thread details:", error);
            toast({
                title: 'エラー',
                description: "教師データのvectorStoreの作成に失敗しました。",
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom'
            });
        }
    };

    useEffect(() => {
        if (postTrainDataResponse) {
            const targetId = postTrainDataResponse.id;
            const index = trainData.findIndex(data => data.id === targetId);

            // インデックスが見つかった場合、新しい配列を作成して更新
            if (index !== -1) {
                const updatedTrainData = [
                    ...trainData.slice(0, index),
                    postTrainDataResponse,
                    ...trainData.slice(index + 1)
                ];

                // 更新したtrainData配列をステートにセット
                setTrainData(updatedTrainData);
            } else {
                // インデックスが見つからなかった場合、新しい配列を作成して追加
                const updatedTrainData = [
                    postTrainDataResponse,
                    ...trainData
                ];

                if (updatedTrainData.length > PER_PAGE) {
                    updatedTrainData.length = PER_PAGE; // 配列を15要素に切り詰める
                }

                // 更新したtrainData配列をステートにセット
                setTrainData(updatedTrainData);
            }

        }
        if (postTrainDataError) {
            console.error("POST Error:", postTrainDataError);
        }
    }, [postTrainDataResponse, postTrainDataError, trainData]);

    useEffect(() => {
        const openModalFromParams = () => {
            if (searchParams.get('open_new_modal') === 'true') {
                const inquiry = decodeURIComponent(searchParams.get('inquiry') || '');
                const reply = decodeURIComponent(searchParams.get('reply') || '');
                // setModalId('new'); // 新規作成の場合はIDを'new'とする
                openModal({ "id": "new", "question": inquiry, "answer": reply, "is_active": true });
            }
        };

        openModalFromParams();
    }, [searchParams, onModalOpen, setModalQuestion, setModalAnswer, setModalIsActive, setModalId]);

    return (
        <div>
            <Header />
            <Container>
                <Heading as='h2' size='md'>教師データ</Heading>

                <Flex justifyContent="flex-end">
                    <Button onClick={() => generateVectorStore()} colorScheme="brand" variant="link" mr={8}>教師データ生成</Button>
                    <Button onClick={() => openModal({ "id": "new", "question": "", "answer": "", "is_active": true })} colorScheme="brand">新規作成</Button>
                </Flex>
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>質問</Th>
                            <Th>回答</Th>
                            <Th></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {trainData.map((data: any) => (
                            <Tr key={data.id}>
                                <Td h={"30"}><Text>{data.question.replace(/\r?\n/g, " ").slice(0, 150)}</Text></Td>
                                <Td><Text>{data.answer.replace(/\r?\n/g, " ").slice(0, 150)}</Text></Td>
                                <Td><Button onClick={() => openModal(data)} colorScheme="brand">編集</Button></Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
                <Flex justifyContent="center" alignItems="center" mt={4}>
                    <>
                        <Button onClick={() => goToPage(1)} isDisabled={page === 1}>最初</Button>
                        <Button onClick={() => goToPage(page - 1)} mx={2} isDisabled={page === 1}>前へ</Button>
                    </>
                    <Text>ページ {page} / {totalPages}</Text>
                    <>
                        <Button onClick={() => goToPage(page + 1)} mx={2} isDisabled={page >= totalPages}>次へ</Button>
                        <Button onClick={() => goToPage(totalPages)} isDisabled={page >= totalPages}>最後</Button>
                    </>
                </Flex>
            </Container>

            <Modal isOpen={isModalOpen} onClose={onModalClose}>
                <ModalOverlay />
                <ModalContent maxW="980px">
                    <ModalHeader display="flex" justifyContent={"space-between"}>
                        <Heading as='h4' size='md'>
                            教師データを保存
                        </Heading>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex justifyContent="flex-end">
                            <Button mt={4} onClick={handleModalDeleteSubmit} colorScheme="red" variant={"link"}>削除</Button>
                        </Flex>
                        <form onSubmit={handleModalSubmit}>
                            <FormControl isRequired>
                                <FormLabel htmlFor="question">質問</FormLabel>
                                <Textarea id="question" placeholder="質問を入力してください" resize="vertical" minH={200} value={modalQuestion} onChange={e => setModalQuestion(e.target.value)} />
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel htmlFor="answer">回答</FormLabel>
                                <Textarea id="answer" placeholder="回答を入力してください" resize="vertical" minH={200} value={modalAnswer} onChange={e => setModalAnswer(e.target.value)} />
                            </FormControl>
                            <Flex justifyContent="space-between" alignItems={"end"}>
                                <FormControl isRequired w={"120px"} display={"flex"} alignItems={"end"} mt={"20px"}>
                                    <FormLabel htmlFor='isActive' mb={"0"}>
                                        有効
                                    </FormLabel>
                                    <Switch id='isActive' size='lg' isChecked={modalIsActive} onChange={e => setModalIsActive(e.target.checked)} />
                                </FormControl>
                                <small>{modalTrainedAt}</small>
                            </Flex>
                            <Flex justifyContent="center">
                                <Button mt={4} colorScheme="brand" type="submit" minW={"165px"}>保存</Button>
                            </Flex>
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    );
};

export default TrainDataList;