// useAuth.ts
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

interface UseAuthOptions {
    redirectTo?: string;
}

interface User {
    last_name: string;
    first_name: string;
    id: string;
    email: string;
    need_to_sync: boolean;
}

const useAuth = ({ redirectTo = "/login" }: UseAuthOptions = {}) => {
    const [user, setUser] = useState<User | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                };
                const response = await axios.get<User>(
                    `${process.env.REACT_APP_BACKEND_APP_URL}/api/check-auth/`,
                    config
                );
                setUser(response.data); // Set user data
            } catch (error: any) {
                if (error.response && error.response.status === 401) {
                    navigate(redirectTo);
                } else if (error.response) {
                    console.error("An error occurred:", error.response.data);
                } else if (error.request) {
                    console.error("No response was received:", error.request);
                } else {
                    console.error("A general error occurred:", error.message);
                }
            }
        };

        fetchData();
    }, [redirectTo, navigate]);

    return user;
};

export default useAuth;
