import React, { useState } from "react";
import { Container, Heading, Link, Table, Thead, Tbody, Tr, Th, Td, Flex, Button } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import Header from "../partials/Header";
import Loading from "../partials/Loading";
import Error from "../partials/Error";
import useAuth from "../hooks/useAuth";
import useStores from "../hooks/useStores";

const ITEMS_PER_PAGE = 30;

const ThreadList: React.FC = () => {
    useAuth();
    const { threads, loading, error } = useStores();
    const [currentPage, setCurrentPage] = useState(1);

    const maxPage = Math.ceil(threads.length / ITEMS_PER_PAGE);
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const selectedThreads = threads.slice(startIndex, startIndex + ITEMS_PER_PAGE);

    const changePage = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div>
            <Header />
            <Container>
                <Heading as='h2' size='md'>ストア一覧</Heading>
                {loading ? (
                    <Loading />
                ) : error ? (
                    <Error error={error} />
                ) : (
                    <>
                        <Table variant="simple">
                            <Thead>
                                <Tr>
                                    <Th>ストア名</Th>
                                    <Th>概要</Th>
                                    <Th></Th>
                                    <Th></Th>
                                    <Th></Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {selectedThreads.map((store: any) => (
                                    <Tr key={store.id}>
                                        <Td>{store.title}</Td>
                                        <Td>{store.memo}</Td>
                                        <Td minW="80px">
                                            <Link as={RouterLink} to={`/store/${store.id}/setting/`}>
                                                分類
                                            </Link>
                                        </Td>
                                        <Td minW="80px">
                                            <Link as={RouterLink} to={`/store/${store.id}/ticket/`}>
                                                チケット
                                            </Link>
                                        </Td>
                                        <Td minW="80px">
                                            <Link as={RouterLink} to={`/store/${store.id}/thread/`}>
                                                スレッド
                                            </Link>
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                        {/* <Flex justifyContent="center" mt={4}>
                            {Array.from({ length: maxPage }, (_, index) => index).map((page) => (
                                <Button
                                    key={page}
                                    mx={1}
                                    onClick={() => changePage(page + 1)}
                                    colorScheme={currentPage === page + 1 ? "blue" : "gray"}
                                >
                                    {page + 1}
                                </Button>
                            ))}
                        </Flex> */}
                    </>
                )}
            </Container>
        </div>
    );
};

export default ThreadList;