import React from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate,
} from "react-router-dom";

import Login from "./views/Login";
import Dashboard from "./views/Dashboard";
import TicketList from "./views/TicketList";
import TicketDetail from "./views/TicketDetail";
import TrainDataList from "./views/TrainDataList";
import ThreadList from "./views/ThreadList";
import ThreadDetail from "./views/ThreadDetail";
import Setting from "./views/Setting";

const App: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/store/:storeId/ticket" element={<TicketList />} />
                <Route
                    path="/ticket/:id"
                    element={<TicketDetail />}
                />
                <Route path="/store/:storeId/thread" element={<ThreadList />} />
                <Route
                    path="/thread/:id"
                    element={<ThreadDetail />}
                />
                <Route path="/store/:storeId/setting" element={<Setting />} />
                <Route path="/classification/:classificationId/train-data" element={<TrainDataList />} />
                <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
        </Router>
    );
};

export default App;
