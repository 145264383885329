import { useState } from "react";
import axios from "axios";
import { useToast } from "@chakra-ui/react"; // Import useToast from Chakra UI

const useDeleteApi = <T>(url: string) => {
    const [data, setData] = useState<T | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    // Initialize the Chakra UI toast hook
    const toast = useToast();

    const deleteData = async (payload?: any): Promise<void> => {
        setLoading(true);
        try {
            const response = await axios.delete<T>(url, {
                data: payload, // Pass payload if needed for the delete request
                withCredentials: true,
            });
            setData(response.data); // Assuming the server might return some data on deletion

            // Success toast message
            toast({
                title: "削除成功",
                description: "データが正常に削除されました。",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (err) {
            setError("データの削除に失敗しました。");

            // Error toast message
            toast({
                title: "削除エラー",
                description: "データの削除に失敗しました。",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };

    return { deleteData, data, loading, error };
};

export default useDeleteApi;
