import React, { useEffect, useState, useRef } from "react";
import {
    Box, Button, Container, Flex, FormControl, FormLabel, Heading, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, Textarea, useDisclosure,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
} from "@chakra-ui/react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../partials/Header";
import useAuth from "../hooks/useAuth";
import useGetApi from "../hooks/useGetApi";
import usePostApi from "../hooks/usePostApi";
import usePutApi from "../hooks/usePutApi";
import useDeleteApi from "../hooks/useDeleteApi";

type Classification = {
    id: number;
    name: string;
    description: string;
    criteria: string;
    guideline: string;
    template: string;
};

const defaultClassification: Classification = {
    id: 0, // 仮のID、またはバックエンドがIDを無視するか、または新規作成に対して適切な扱いをすることを想定
    name: '',
    description: '',
    criteria: '',
    guideline: '',
    template: '',
};

const ClassificationEditModal: React.FC<{
    classification: Classification;
    isOpen: boolean;
    onClose: () => void;
    fetchClassifications: () => void;
    isCreatingNew: boolean;
    storeId: string | undefined;
}> = ({ classification, isOpen, onClose, fetchClassifications, isCreatingNew, storeId }) => {
    // 分類の状態を管理するためのState
    const [editData, setEditData] = useState<Classification>(classification || defaultClassification);

    useEffect(() => {
        setEditData(classification);
    }, [classification]);

    // 編集されたデータを更新するハンドラ
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setEditData({ ...editData, [e.target.name]: e.target.value });
    };

    const classificationBaseUrl = `${process.env.REACT_APP_BACKEND_APP_URL}/api/classification/`;

    const { postData: createData, loading: createLoading, error: createError } = usePostApi<any, any>(
        classificationBaseUrl,
    );

    const { putData: updateData, loading: updateLoading, error: updateError } = usePutApi<any, any>(
        classificationBaseUrl,
    );
    const { deleteData, loading: deleteLoading, error: deleteError } = useDeleteApi<any>(classificationBaseUrl);

    const handleSave = async () => {
        if (editData.id !== 0) {
            // 編集のAPI呼び出し
            await updateData({
                id: editData.id,
                name: editData.name,
                description: editData.description,
                criteria: editData.criteria,
                guideline: editData.guideline,
                template: editData.template,
            });
        } else {
            // 新規登録のAPI呼び出し
            await createData({
                name: (editData as Classification).name,
                description: (editData as Classification).description,
                criteria: (editData as Classification).criteria,
                guideline: (editData as Classification).guideline,
                template: (editData as Classification).template,
                store_id: storeId,
            });
        }
        onClose();
        fetchClassifications();
    };

    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = React.useState(false);
    const cancelRef = useRef<HTMLButtonElement>(null);
    const onOpenDeleteAlert = () => setIsDeleteAlertOpen(true);
    const onCloseDeleteAlert = () => setIsDeleteAlertOpen(false);

    const handleDeleteConfirm = async () => {
        await handleDelete(editData.id); // 削除処理
        onCloseDeleteAlert(); // ダイアログを閉じる
        onClose(); // モーダルも閉じる
        fetchClassifications()
    };

    const handleDelete = async (classificationId: number) => {
        await deleteData({ id: classificationId }); // Assuming the API requires an object with the id for deletion
    };

    useEffect(() => {
        // 新規作成モードの場合、defaultClassificationを使用
        if (isCreatingNew) {
            setEditData(defaultClassification);
        } else {
            setEditData(classification);
        }
    }, [classification, isCreatingNew]);

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent minW="65vw">
                    <ModalHeader display="flex" justifyContent="space-between">
                        分類の編集
                        <Button colorScheme="red" variant="ghost" onClick={onOpenDeleteAlert} isLoading={deleteLoading}>
                            削除
                        </Button>
                    </ModalHeader>
                    <ModalBody>
                        <FormControl>
                            <FormLabel>分類名
                                <Text display="block" mt={1} fontSize="sm" color="gray.600">
                                    分類の名称を入力してください。（例: 商品情報に関する問い合わせ）
                                </Text>
                            </FormLabel>
                            <Input name="name" value={editData.name} onChange={handleChange} />
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>
                                説明
                                <Text display="block" mt={1} fontSize="sm" color="gray.600">
                                    管理するための説明を記載してください。この説明は内部使用のためのものなので分かりやすい内容であれば何でもかまいません。<br />（例: 商品のサイズやカラー、素材に関する質問）
                                </Text>
                            </FormLabel>
                            <Textarea name="description" value={editData.description} onChange={handleChange} sx={{ height: "auto", minHeight: "200px" }} />
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>
                                判断指標
                                <Text display="block" mt={1} fontSize="sm" color="gray.600">
                                    生成AIがこの判断基準を使って分類を決めます。<br />（例: 問い合わせ内容が商品の詳細（サイズ、カラー、素材）に関する問い合わせかどうか）
                                </Text>
                            </FormLabel>
                            <Textarea name="criteria" value={editData.criteria} onChange={handleChange} sx={{ height: "auto", minHeight: "200px" }} />
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>
                                対応方針
                                <Text display="block" mt={1} fontSize="sm" color="gray.600">
                                    生成AIがこの対応方針に沿って回答を作成します。<br />（例: 販売希望の内容であれば希望を承った旨を回答する。販売中の商品に関する問い合わせの場合は商品情報についてオペレーターが確認する旨を回答する。）
                                </Text>
                            </FormLabel>
                            <Textarea name="guideline" value={editData.guideline} onChange={handleChange} sx={{ height: "auto", minHeight: "200px" }} />
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>
                                回答のベーステンプレート
                                <Text display="block" mt={1} fontSize="sm" color="gray.600">
                                    回答のベースとなる文章。<br />（差込文字については[]で囲んでください。例：[顧客名] 、[返答]）
                                </Text>
                            </FormLabel>
                            <Textarea name="template" value={editData.template} onChange={handleChange} sx={{ height: "auto", minHeight: "200px" }} />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleSave}>
                            保存
                        </Button>
                        <Button variant="ghost" onClick={onClose}>キャンセル</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <AlertDialog
                isOpen={isDeleteAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={onCloseDeleteAlert}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            分類の削除
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            この分類を削除してもよろしいですか？この操作は元に戻せません。
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onCloseDeleteAlert}>
                                キャンセル
                            </Button>
                            <Button colorScheme="red" onClick={handleDeleteConfirm} ml={3}>
                                削除
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
};

const Setting: React.FC = () => {
    useAuth();
    const navigate = useNavigate();
    const { storeId } = useParams<{ storeId: string }>();

    const { fetchData, loading, error } = useGetApi<any>(
        `${process.env.REACT_APP_BACKEND_APP_URL}/api/store/${storeId}/classification/`,
        []
    );

    const [classifications, setClassifications] = useState<any[]>([]);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isCreatingNew, setIsCreatingNew] = useState(false);
    const [currentClassification, setCurrentClassification] = useState<Classification | null>(null);

    const handleEditClick = (classification: Classification) => {
        setCurrentClassification(classification);
        onOpen();
    };

    const fetchClassifications = async () => {
        try {
            const fetchedClassifications = await fetchData();
            setClassifications(fetchedClassifications);
        } catch (error) {
            console.error("データの取得に失敗しました。", error);
        }
    };

    useEffect(() => {
        fetchClassifications();
    }, []);

    return (
        <div>
            <Header />
            <Container maxW="container.xl" py={5}>
                <Flex justifyContent="space-between" alignItems="center" mb={6}>
                    <Heading as="h2" size="lg">回答の分類設定</Heading>
                    <Button onClick={() => { setIsCreatingNew(true); onOpen(); }} colorScheme="brand">新規登録</Button>
                </Flex>
                {classifications.map((classification) => (
                    <Box key={classification.id} p={4} borderWidth="1px" borderRadius="lg" mb={4}>
                        <Flex alignItems="center" justifyContent="space-between">
                            <Box>
                                <Text fontWeight="bold">{classification.name}</Text>
                                <Text mt={2}>{classification.description}</Text>
                            </Box>
                            <Button mt={4} colorScheme="brand" onClick={() => navigate('/classification/' + classification.id + '/train-data')} variant={"link"} ml={"auto"} mr={"10px"}>
                                教師データ
                            </Button>
                            <Button mt={4} colorScheme="brand" onClick={() => handleEditClick(classification)}>
                                編集
                            </Button>
                        </Flex>
                        {!(classification.train_data_generated_at) && (<Flex justifyContent="flex-end"><Text color="red">教師データ未生成</Text></Flex>)}
                    </Box>
                ))}
            </Container>
            {isOpen && (
                <ClassificationEditModal
                    classification={isCreatingNew ? defaultClassification : currentClassification || defaultClassification}
                    isOpen={isOpen}
                    onClose={() => {
                        onClose();
                        setIsCreatingNew(false);
                        fetchClassifications();
                    }}
                    fetchClassifications={fetchClassifications}
                    isCreatingNew={isCreatingNew}
                    storeId={storeId}
                />
            )}
        </div >
    );
};

export default Setting;