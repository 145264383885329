import React, { useEffect, useState } from "react";
import { Container, Heading, Link, Table, Thead, Tbody, Tr, Th, Td, Flex, Button } from "@chakra-ui/react";
import { Link as RouterLink, useParams } from "react-router-dom";
import Header from "../partials/Header";
import Loading from "../partials/Loading";
import Error from "../partials/Error";
import useAuth from "../hooks/useAuth";
import useGetApi from "../hooks/useGetApi";

const ITEMS_PER_PAGE = 100;

const ThreadList: React.FC = () => {
    useAuth();
    const { storeId } = useParams<{ storeId?: string }>();

    const { fetchData, loading, error } = useGetApi<any>(
        `${process.env.REACT_APP_BACKEND_APP_URL}/api/store/${storeId}/thread/`,
        []
    );
    const [currentPage, setCurrentPage] = useState(1);

    const [threads, setThreads] = useState<any[]>([]);

    const maxPage = Math.ceil(threads.length / ITEMS_PER_PAGE);
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const selectedThreads = threads.slice(startIndex, startIndex + ITEMS_PER_PAGE);

    const changePage = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        const fetchThreads = async () => {
            try {
                const fetchThreads = await fetchData();
                console.log(fetchThreads);
                setThreads(fetchThreads);
            } catch (error) {
                console.error("データの取得に失敗しました。");
            }
        };
        fetchThreads();
    }, []);

    return (
        <div>
            <Header />
            <Container>
                <Heading as='h2' size='md'>スレッド一覧</Heading>
                {loading ? (
                    <Loading />
                ) : error ? (
                    <Error error={error} />
                ) : (
                    <>
                        <Table variant="simple">
                            <Thead>
                                <Tr>
                                    <Th>タイトル</Th>
                                    <Th>作成日時</Th>
                                    <Th>詳細</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {selectedThreads.map((thread: any) => (
                                    <Tr key={thread.id}>
                                        <Td>{thread.title}</Td>
                                        <Td>{new Date(thread.created_at).toLocaleString()}</Td>
                                        <Td minW="80px">
                                            <Link as={RouterLink} to={`/thread/${thread.id}`}>
                                                詳細
                                            </Link>
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                        <Flex mt={4} overflowX="scroll">
                            {Array.from({ length: maxPage }, (_, index) => index).map((page) => (
                                <Button
                                    key={page}
                                    mx={1}
                                    onClick={() => changePage(page + 1)}
                                    colorScheme={currentPage === page + 1 ? "blue" : "gray"}
                                >
                                    {page + 1}
                                </Button>
                            ))}
                        </Flex>
                    </>
                )}
            </Container>
        </div>
    );
};

export default ThreadList;