import React, { useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import useGetApi from "../hooks/useGetApi";
import Header from "../partials/Header";
import { Container, Text, Box, Heading } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

const TicketDetail: React.FC = () => {
    useAuth();
    const { id } = useParams<{ id?: string }>();
    const ticketId = id ? parseInt(id, 10) : null;

    const [ticket, setTicket] = useState<any>(null);

    const { fetchData, loading: apiLoading, error: apiError } = useGetApi<any>(
        `${process.env.REACT_APP_BACKEND_APP_URL}/api/ticket/${ticketId}/`,
        null
    );

    useEffect(() => {
        if (ticketId) {
            const getTicketDetail = async () => {
                try {
                    const data = await fetchData();
                    setTicket(data);
                } catch (error) {
                    console.error("Failed to fetch ticket details:", error);
                }
            };

            getTicketDetail();
        }
    }, [ticketId]);

    return (
        <div>
            <Header />
            <Container maxW="container.xl" py={5}>
                {apiLoading && <Text>Loading...</Text>}
                {apiError && <Text color="red.500">Error: {apiError}</Text>}
                {ticket && (
                    <Box>
                        <Heading as="h2" size="lg" mb={4}>{ticket.title}</Heading>
                        <Text mb={2}><strong>担当者:</strong> {ticket.assignee}</Text>
                        <Text mb={2}><strong>ステータスコード:</strong> {ticket.status_cd}</Text>
                        <Text mb={2}><strong>作成日:</strong> {new Date(ticket.relation_created_at).toLocaleString()}</Text>
                        {ticket.messages.map((message: any) => (
                            <Box key={message.id} p={4} borderWidth="1px" borderRadius="lg" mb={4}>
                                <Text mb={2}><strong>From:</strong> {message.message_from}</Text>
                                <Text mb={2}><strong>To:</strong> {message.message_to}</Text>
                                <Text mb={2}><strong>Sent:</strong> {new Date(message.sent_at).toLocaleString()}</Text>
                                <Text mb={2} style={{ whiteSpace: 'pre-wrap' }}><strong>Message:</strong> {message.body}</Text>
                                {message.comments && message.comments.length > 0 && (
                                    <Box mt={4}>
                                        <Text mb={2}><strong>Comments:</strong></Text>
                                        {message.comments.map((comment: any) => (
                                            <Box key={comment.id} pl={4} mb={2}>
                                                <Text><strong>{comment.name}</strong> ({new Date(comment.commented_at).toLocaleString()}): {comment.comment_body}</Text>
                                            </Box>
                                        ))}
                                    </Box>
                                )}
                            </Box>
                        ))}
                    </Box>
                )}
            </Container>
        </div>
    );
};

export default TicketDetail;